import { OrderTemplateService } from "@/services/OrderTemplate/OrderTemplateService";
import { ref } from "vue";
export default function (allData) {
    const allTemplate = OrderTemplateService.TemplateList;
    const showAddTemplateDlg = ref(false);
    const goAddTemplate = () => {
        showAddTemplateDlg.value = true;
    };
    const onDetailDlgClose = () => {
        showAddTemplateDlg.value = false;
    };
    const addTemplateSuccess = async () => {
        await OrderTemplateService.GetAllTemplateList();
        showAddTemplateDlg.value = false;
        allData.data.productInfo.templateId = OrderTemplateService.GetLastTemplate()?.id ?? 0;
    };
    return {
        allTemplate,
        showAddTemplateDlg,
        goAddTemplate,
        onDetailDlgClose,
        addTemplateSuccess
    };
}
