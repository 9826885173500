import SpecService from "@/services/Specs/SpecServices";
import { computed, defineComponent } from "vue";
import SpecItem from "./specItem.vue";
import specsFunc from "./specs";
export default defineComponent({
    components: {
        SpecItem,
    },
    setup() {
        const { addSpec, delSpec, onSpecChanged } = specsFunc();
        const specs = SpecService.GetSpecs();
        const noAddSpec = computed(() => {
            return SpecService.MaxSpecCount <= specs.length;
        });
        return { addSpec, delSpec, onSpecChanged, specs, noAddSpec };
    },
});
