import SpecService from "./SpecServices";
export class SpecContentItem {
    constructor() {
        // 规格详情表id
        this.id = "";
        // 规格内容
        this.content = "";
        // 规格图片
        this.pics = "";
        this.id = SpecService.GetRandomContentId();
    }
}
export class SpecItem {
    constructor() {
        this.id = "";
        // 规格名
        this.name = "";
        // 规格类型
        this.type = 1;
        // 规格内容列表
        this.contentList = new Array();
        // 规格图片
        this.pics = "";
        // 是否打开图片
        this.picExist = false;
        this.id = SpecService.GetRandomSpecId();
    }
}
