import eventBus from "@/utils/eventBus";
import { onEditDataIniting } from "@/utils/eventBusConst";
import { reactive, ref } from "vue";
export default function DateTimeFunc(addForm) {
    const start = new Date();
    const saleTimeRange = ref([
        start,
        new Date(start.getFullYear() + 10, start.getMonth() + 1, start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds()),
    ]);
    const defaultTimeRange = reactive([
        new Date(0, 0, 0, 0, 0, 0),
        new Date(0, 0, 0, 23, 59, 59),
    ]);
    eventBus.on(onEditDataIniting, (data) => {
        saleTimeRange.value.splice(0, saleTimeRange.value.length);
        if (data.startTime) {
            saleTimeRange.value.push(data.startTime);
        }
        if (data.endTime) {
            saleTimeRange.value.push(data.endTime);
        }
    });
    const onSaleTimeChanged = (val) => {
        if (!val) {
            addForm.data.productInfo.startTime = "";
            addForm.data.productInfo.endTime = "";
            return;
        }
        addForm.data.productInfo.startTime = val[0];
        addForm.data.productInfo.endTime = val[1];
    };
    return {
        saleTimeRange,
        defaultTimeRange,
        onSaleTimeChanged
    };
}
