import TagsService from "@/services/Goods/TagsService";
import eventBus from "@/utils/eventBus";
import { onEditDataIniting } from "@/utils/eventBusConst";
import { computed, reactive, ref } from "vue";
export default function TagsFunc() {
    // 商品标签
    const tags = reactive(new Array());
    const showTags = ref(false);
    // 所有商品标签
    const allTags = reactive(new Array());
    // tag输入内容
    const tagText = ref("");
    TagsService.GetAllTags().then(res => {
        res.forEach(x => {
            allTags.push(x);
        });
    });
    eventBus.on(onEditDataIniting, (data) => {
        showTags.value = true;
        data.label &&
            data.label.split(",").forEach((item) => {
                if (item) {
                    tags.push({ value: item });
                }
            });
    });
    const handleTagSelect = (tag) => {
        const hasTag = tags.filter((x) => {
            return x.value && x.value.indexOf(tag.value || '') >= 0;
        });
        if (hasTag.length <= 0) {
            tags.push(tag);
        }
    };
    const handleTagClose = (index) => {
        tags.splice(index, 1);
    };
    const filterTag = computed(() => {
        const filterText = tagText;
        if (!filterText) {
            return allTags;
        }
        const result = allTags.filter((x) => {
            return x.value && x.value.indexOf(tagText.value) >= 0;
        });
        return result;
    });
    return {
        tags,
        allTags,
        showTags,
        tagText,
        filterTag,
        handleTagSelect,
        handleTagClose
    };
}
