import { OptionType, PriceStatus, ProductType, RecordType, VerifyStatus } from "@/common/enum";
import router from "@/router";
import { BreadCrumb } from "@/services/BreadCrumb/BreadCrumb";
import BreadCrumbService from "@/services/BreadCrumb/BreadCrumbService";
import { computed, nextTick, onMounted, reactive, ref, shallowRef, watch } from "vue";
import TagsFunc from './TagsFunc';
import DateTimeFunc from "./DateTimeFunc";
import { DefaultTemplateId, EditGoodsAllArgs } from "@/api/GoodsApi";
import { SaveVerifyRecord, GoodsVerify, GetVerfifyGoodsInfoById, GetGoodsByProductNo, } from "@/api/GoodsApi";
import eventBus from "@/utils/eventBus";
import SpecService from "@/services/Specs/SpecServices";
import SkuService from '@/services/Skus/SkuServices';
import RuleFunc from "./RuleFunc";
import { CataFunc } from "./CataFunc";
import { GetImageSize } from "@/common/utils";
import { ElLoading, ElMessage } from "element-plus";
import { GetAuthToken } from "@/api/Request";
import { FormatterDateTime } from "@/common/formatter";
import { onSkuError, onEditDataIniting } from "@/utils/eventBusConst";
import { OrderTemplateService } from "@/services/OrderTemplate/OrderTemplateService";
import ProductSupplierService from "@/services/ProductSupplier/ProductSupplierService";
import ShopService from "@/services/Shop/ShopService";
export default function DetailFunc() {
    const editorRef = shallowRef();
    // 售后设置组件ref
    const afterSaleSettingRef = ref("");
    // 提交审核按钮loading状态
    const comLoading = ref(false);
    const formLoading = ref(true);
    // 预览图片弹窗显示
    const dialogVisible = ref(false);
    // 弹窗显示的图片地址
    const dialogImageUrl = ref("");
    const { tags, allTags, showTags, tagText, filterTag, handleTagSelect, handleTagClose } = TagsFunc();
    const productNo = ref("");
    const introducePics = ref("");
    const pics = ref("");
    const detailPics = ref("");
    const verifyStatus = ref(VerifyStatus.None);
    const isAddPage = computed(() => {
        return router.currentRoute.value.fullPath.indexOf('add') > 0;
    });
    const isEditPage = computed(() => {
        return router.currentRoute.value.fullPath.indexOf('edit') > 0;
    });
    const isWaitEditPage = computed(() => {
        return router.currentRoute.value.fullPath.indexOf('waitEdit') > 0;
    });
    const isCopyPage = computed(() => {
        return router.currentRoute.value.fullPath.indexOf('copy') > 0;
    });
    const allData = reactive({ data: new EditGoodsAllArgs() });
    allData.data.productInfo.detailTextPosition = 1;
    const { allTypes, cateParames, selectedValue, dropTree, onCateChanged } = CataFunc(allData);
    const { rules } = RuleFunc(allData, introducePics, pics, detailPics, afterSaleSettingRef, cateParames, editorRef);
    const { saleTimeRange, defaultTimeRange, onSaleTimeChanged } = DateTimeFunc(allData);
    const goodsform = ref(null);
    let loadingInstance;
    watch(allData.data.productInfo, (newVal, oldValue) => {
        if (newVal.productType == ProductType.entity) {
            allData.data.productInfo.templateId = DefaultTemplateId.entity;
            // if (!allData.data.productInfo.templateId || allData.data.productInfo.templateId == DefaultTemplateId.virtual) {
            // }
        }
        if (newVal.productType == ProductType.virtual) {
            if (!allData.data.productInfo.templateId || allData.data.productInfo.templateId == DefaultTemplateId.entity) {
                allData.data.productInfo.templateId = DefaultTemplateId.virtual;
            }
        }
    });
    const fetchData = () => {
        allData.data.productInfo.productType = ProductType.entity;
        formLoading.value = true;
        if (isWaitEditPage.value) {
            const goodsId = router.currentRoute.value.params.id;
            verifyStatus.value = router.currentRoute.value.query.verifyType;
            GetVerfifyGoodsInfoById(parseInt(goodsId)).then((res) => {
                initData(res);
            });
        }
        else {
            if (isEditPage.value || isCopyPage.value) {
                productNo.value = router.currentRoute.value.params.productNo;
                GetGoodsByProductNo(productNo.value).then((res) => {
                    initData(res);
                });
            }
        }
        BreadCrumbService.InsertBreadCrumb(new BreadCrumb("goods-add", "", isEditPage.value ? "编辑商品" : "添加商品"));
        if (!isEditPage.value) {
            loadingInstance.close();
        }
    };
    const initData = (res) => {
        if (!res.isSuccess) {
            return;
        }
        allData.data = JSON.parse(JSON.stringify(res.resultData));
        allData.data.productInfo.detailText = '';
        SkuService.SetStatus(allData.data.productInfo.settleStatus, allData.data.productInfo.selfSaleStatus);
        selectedValue.value = allData.data.productInfo?.productCategoryId || -1;
        eventBus.emit(onEditDataIniting, allData.data.productInfo);
        SpecService.ClearSpecs();
        if (allData.data.productInfo?.specList && allData.data.productInfo?.specList.length > 0) {
            for (let i = 0; i < allData.data.productInfo.specList.length; i++) {
                SpecService.AddSpecs(allData.data.productInfo.specList[i]);
            }
        }
        SkuService.ClearSkus();
        if (allData.data.productInfo?.skuList && allData.data.productInfo?.skuList.length > 0) {
            for (let m = 0; m < allData.data.productInfo?.skuList.length; m++) {
                SkuService.AddSkus(allData.data.productInfo.skuList[m]);
            }
        }
        SkuService.UpdateAllEnableStatus();
        if (isCopyPage.value) {
            allData.data.productNo = '';
        }
        nextTick(() => {
            allData.data.productInfo.detailText = res.resultData.productInfo.detailText;
            loadingInstance.close();
        });
    };
    const beforeDetailUpload = (val) => {
        return new Promise((reslove, reject) => {
            GetImageSize(val).then((res) => {
                if (res.width != res.height) {
                    ElMessage.error("图片上传失败，图片尺寸比例应为1:1");
                    reject();
                }
                reslove();
            });
        });
    };
    const getImgsUrl = (refDom) => {
        if (refDom.value.getImgList().length > 0) {
            return refDom.value.getImgList().map((i) => {
                return i.url;
            }).join(",");
        }
        return '';
    };
    // 空价格处理
    const fillPrice = () => {
        return SkuService.GetSkusNoId().map((i) => {
            i.price = i.price || 0;
            i.selfSalePrice = i.selfSalePrice || 0;
            return i;
        });
    };
    const getVerifyRecord = () => {
        allData.data.merchantId = ShopService.GetCurrentShop().merchantId;
        allData.data.merchantStoreId = ShopService.GetCurrentShop().merchantStoreId || ShopService.GetCurrentShop().id;
        allData.data.productInfo.specList = SpecService.GetSpecs();
        allData.data.productInfo.merchantId = allData.data.merchantId;
        allData.data.productInfo.merchantStoreId = allData.data.merchantStoreId;
        allData.data.productInfo.skuList = fillPrice();
        allData.data.productInfo.selfSaleStatus = SkuService.selfSaleStatus.value;
        allData.data.productInfo.settleStatus = SkuService.settleStatus.value;
        allData.data.productInfo.introducePics = getImgsUrl(introducePics);
        allData.data.productInfo.pics = getImgsUrl(pics);
        allData.data.productInfo.detailPics = getImgsUrl(detailPics);
        allData.data.productInfo.afterSalesStatus = afterSaleSettingRef.value.productAfterSalesDTO.afterSalesStatus;
        allData.data.productInfo.productAfterSalesLabelDTO = { ...afterSaleSettingRef.value.productAfterSalesDTO };
        let labelStr = "";
        tags.forEach((item) => {
            if (item.value) {
                labelStr += item.value + ",";
            }
        });
        allData.data.productInfo.label = labelStr;
        allData.data.productInfo.startTime = FormatterDateTime(saleTimeRange.value[0], "yyyy-MM-dd hh:mm:ss");
        allData.data.productInfo.endTime = FormatterDateTime(saleTimeRange.value[1], "yyyy-MM-dd hh:mm:ss");
        allData.data.createdTime = FormatterDateTime(allData.data.createdTime, "yyyy-MM-dd hh:mm:ss");
        allData.data.updatedTime = FormatterDateTime(allData.data.updatedTime, "yyyy-MM-dd hh:mm:ss");
        allData.data.vertifyTime = "";
        allData.data.optionType = isEditPage.value ? OptionType.Edit : OptionType.Create;
        allData.data.recordType = isWaitEditPage.value ? RecordType.WaitGoods : RecordType.Goods;
        if (isWaitEditPage.value) {
            allData.data.verifyStatus = verifyStatus.value;
        }
        allData.data.productInfo.shareRatio = 0.6;
        allData.data.productInfo.productCategoryParam?.forEach(item => {
            item.content = item.content ?? '';
        });
    };
    const beforeSave = () => {
        const enableSkus = SkuService.GetAllEnableSkus();
        if (enableSkus.length < 1) {
            ElMessage.error("至少需要启用一个规格组合定价");
            return false;
        }
        if (SkuService.settleStatus.value != PriceStatus.open && SkuService.selfSaleStatus.value != PriceStatus.open) {
            ElMessage.error("自营和分销模式必须选一个");
            return false;
        }
        return true;
    };
    const processError = (errorInfo) => {
        for (const key in errorInfo) {
            if (Array.isArray(errorInfo[key])) {
                goodsform.value.scrollToField(key);
                if (key == "skus") {
                    eventBus.emit(onSkuError);
                }
                break;
            }
        }
    };
    const saveData = () => {
        console.log(EditGoodsAllArgs, '11111');
        console.log(goodsform, '222222');
        console.log(allData, 'ddddd');
        if (!beforeSave()) {
            return;
        }
        loadingInstance = ElLoading.service({
            target: ".el-main"
        });
        goodsform.value.validate((valid, errorInfo) => {
            loadingInstance.close();
            if (valid) {
                getVerifyRecord();
                SaveVerifyRecord(allData.data).then((result) => {
                    if (result.isSuccess) {
                        ElMessage.success({
                            message: "添加成功",
                            type: "success",
                        });
                        router.push({
                            path: "/goods/wait",
                        });
                        return;
                    }
                    ElMessage.success({
                        message: "保存失败，请稍候重试",
                        type: "success",
                    });
                });
            }
            else {
                processError(errorInfo);
                return false;
            }
        });
    };
    const commit = () => {
        if (!beforeSave()) {
            return;
        }
        loadingInstance = ElLoading.service({
            target: ".el-main"
        });
        goodsform.value.validate((valid, errorInfo) => {
            if (editorRef.value.getText().trim().length == 0) {
                allData.data.productInfo.detailText = '';
            }
            loadingInstance.close();
            if (valid) {
                getVerifyRecord();
                comLoading.value = true;
                GoodsVerify(allData.data).then((res) => {
                    comLoading.value = false;
                    if (res.isSuccess) {
                        ElMessage.success(res.resultMsg);
                        router.push({
                            path: "/goods/wait",
                        });
                        return;
                    }
                    ElMessage.success({
                        message: "提交失败，请稍候重试",
                        type: "success",
                    });
                }).catch(() => {
                    comLoading.value = false;
                });
            }
            else {
                processError(errorInfo);
                return false;
            }
        });
    };
    onMounted(async () => {
        loadingInstance = ElLoading.service({
            target: ".el-main"
        });
        BreadCrumbService.ClearBreadCrumb();
        BreadCrumbService.InsertBreadCrumb(new BreadCrumb("1", "", "商品管理"));
        fetchData();
        await ProductSupplierService.GetProductSupplierListAsync();
        await OrderTemplateService.GetAllTemplateList();
    });
    const CustomHeader = computed(() => {
        const token = GetAuthToken();
        return {
            Authorization: token,
            authStore: ShopService.GetCurrentShop().id
        };
    });
    watch(() => router.currentRoute.value.path, (newValue, oldValue) => {
        if (newValue.includes('add') && oldValue && !(oldValue.includes('add'))) {
            router.push({
                path: '/goods/empty',
                query: {
                    route: '/goods/add',
                },
            });
        }
    }, { immediate: true });
    return {
        editorRef,
        afterSaleSettingRef,
        allData,
        selectedValue,
        dialogVisible,
        dialogImageUrl,
        rules,
        allTypes,
        tags,
        allTags,
        showTags,
        tagText,
        filterTag,
        productNo,
        saleTimeRange,
        defaultTimeRange,
        cateParames,
        handleTagSelect,
        handleTagClose,
        onCateChanged,
        onSaleTimeChanged,
        beforeDetailUpload,
        saveData,
        commit,
        CustomHeader,
        introducePics,
        pics,
        detailPics,
        goodsform,
        comLoading,
        formLoading,
        dropTree,
        isEditPage,
    };
}
