import { PriceStatus, SkuEnableStatus } from "@/common/enum";
import MerchantService from "@/services/Merchant/MerchantService";
import SkuServices from "@/services/Skus/SkuServices";
import eventBus from "@/utils/eventBus";
import { onInitSkused } from "@/utils/eventBusConst";
import { ElMessage } from "element-plus";
import { computed, reactive, ref, watch } from "vue";
export default function skuFunc() {
    const totalPriceDlg = ref(false);
    const settleStatus = SkuServices.settleStatus; // 分销
    const selfSaleStatus = SkuServices.selfSaleStatus; // 自营
    const setPriceRef = ref(null);
    const showCloumns = reactive(new Array());
    showCloumns.push({ title: "库存", value: "stock" });
    showCloumns.push({ title: "市场价", value: "originalPrice" });
    if (settleStatus.value == PriceStatus.open) {
        showCloumns.push({ title: "分销成本价", value: "price" });
    }
    if (selfSaleStatus.value == PriceStatus.open) {
        showCloumns.push({ title: "自营销售价", value: "selfSalePrice" });
    }
    SkuServices.UpdateColumns(showCloumns);
    eventBus.on(onInitSkused, () => {
        SkuServices.UpdateAllEnableStatus();
    });
    const goTotalSetPrice = () => {
        setPriceRef.value.priceValid().then((val) => {
            SkuServices.UpdateSkusPriceAndSotck(val);
            totalPriceDlg.value = false;
        }).catch((err) => {
            ElMessage.error("请正确输入定价数值");
        });
    };
    const removeShowCloumns = (value) => {
        for (let i = 0; i < showCloumns.length; i++) {
            if (showCloumns[i].value == value) {
                showCloumns.splice(i, 1);
                break;
            }
        }
    };
    const onAllEnableChanged = (val) => {
        SkuServices.UpdateSkusEnableStatus(val);
    };
    const onEnableChanged = (val) => {
        if (val == SkuEnableStatus.Normal) {
            SkuServices.UpdateAllEnableStatus();
            return;
        }
        SkuServices.UpdateAllEnableStatus();
    };
    watch(showCloumns, () => {
        SkuServices.UpdateColumns(showCloumns);
    });
    watch(settleStatus, () => {
        if (settleStatus.value == PriceStatus.open) {
            showCloumns.push({ title: "分销成本价", value: "price" });
            return;
        }
        removeShowCloumns("price");
    });
    watch(selfSaleStatus, () => {
        if (selfSaleStatus.value == PriceStatus.open) {
            showCloumns.push({ title: "自营销售价", value: "selfSalePrice" });
            return;
        }
        removeShowCloumns("selfSalePrice");
    });
    watch(settleStatus, () => {
        SkuServices.ChangePriceStatus('settleStatus', settleStatus);
    });
    watch(selfSaleStatus, () => {
        SkuServices.ChangePriceStatus('selfSaleStatus', selfSaleStatus);
    });
    const openProductSettle = computed(() => {
        return MerchantService.IsOpenProductSettle;
    });
    if (!MerchantService.IsOpenProductSettle) {
        settleStatus.value = PriceStatus.close;
    }
    else {
        settleStatus.value = PriceStatus.open;
    }
    return {
        totalPriceDlg,
        settleStatus,
        openProductSettle,
        selfSaleStatus,
        showCloumns,
        setPriceRef,
        onAllEnableChanged,
        goTotalSetPrice,
        onEnableChanged,
    };
}
