import { GetProductCateList } from "@/api/GoodsApi";
import CategoryService from "@/services/Goods/CategoryService";
import eventBus from "@/utils/eventBus";
import { onEditCateParamsLoaded, onEditDataIniting } from "@/utils/eventBusConst";
import { reactive, ref } from "vue";
export function CataFunc(addForm) {
    // 商品所有分类
    const allTypes = CategoryService.GetAllCatagory();
    const cateParames = reactive(new Array());
    const dropTree = ref(null);
    // 商品分类id
    const selectedValue = ref(-1);
    const onCateChanged = (val) => {
        addForm.data.productInfo.productCategoryId = val.id;
        addForm.data.productInfo.productCategoryParam = [];
        getCateParams(val.id);
    };
    const getCateParams = (id) => {
        if (!id) {
            return;
        }
        GetProductCateList({ id: id }).then(res => {
            if (res.isSuccess) {
                cateParames.splice(0, cateParames.length);
                for (let i = 0; i < res.resultData.length; i++) {
                    res.resultData[i]['importantParam'] = false;
                    res.resultData[i].content = res.resultData[i].content ?? "";
                    cateParames.push(res.resultData[i]);
                }
                if (!addForm.data.productInfo.productCategoryParam || addForm.data.productInfo.productCategoryParam.length <= 0) {
                    addForm.data.productInfo.productCategoryParam = cateParames;
                }
                eventBus.emit(onEditCateParamsLoaded, { data: cateParames });
            }
        });
    };
    eventBus.on(onEditDataIniting, () => {
        getCateParams(addForm.data.productInfo.productCategoryId);
    });
    return {
        allTypes,
        selectedValue,
        dropTree,
        cateParames,
        onCateChanged
    };
}
