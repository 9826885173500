import { CateParamesType } from "@/common/enum";
import SkuServices from "@/services/Skus/SkuServices";
import SpecService from "@/services/Specs/SpecServices";
import eventBus from "@/utils/eventBus";
import { onEditCateParamsLoaded } from "@/utils/eventBusConst";
import { reactive } from "vue";
export default function RuleFunc(allData, introducePics, pics, detailPics, afterSaleSettingRef, cateParames, detailTextRef) {
    const checkproductCategoryId = () => {
        if (!allData.data.productInfo || !allData.data.productInfo.productCategoryId) {
            return false;
        }
        return allData.data.productInfo.productCategoryId > 0;
    };
    const checkPics = () => {
        return pics.value.getImgList().length > 0;
    };
    const checkdetailPics = () => {
        const imgs = detailPics.value.getImgList();
        return imgs.length <= 50 && imgs.length > 0;
    };
    const checkdetailText = () => {
        return detailTextRef.value.getText().trim().length < 3000;
    };
    const checkintroducePics = () => {
        const imgs = introducePics.value.getImgList();
        return imgs.length <= 10 && imgs.length > 0;
    };
    const rules = reactive({
        name: [
            { required: true, message: "商品名称必须填写", trigger: "blur" },
            {
                min: 1,
                max: 50,
                message: "长度在 1 到 50 个字符",
                trigger: "blur",
            },
        ],
        description: [
            { required: true, message: "商品描述必须填写", trigger: "blur" },
            {
                min: 1,
                max: 100,
                message: "长度在 1 到 100 个字符",
                trigger: "blur",
            },
        ],
        productCategoryId: [
            {
                validator: checkproductCategoryId,
                required: true,
                message: "请选择商品类别",
                trigger: "blur",
            },
        ],
        pics: [
            {
                validator: checkPics,
                required: true,
                message: "最少添加一张商品缩略图",
                trigger: "blur",
            },
        ],
        introducePics: [
            {
                validator: checkintroducePics,
                required: true,
                message: "最少添加一张商品介绍图",
                trigger: "blur",
            },
        ],
        detailPics: [
            {
                validator: checkdetailPics,
                required: true,
                message: "最少添加一张商品详情图",
                trigger: "blur",
            },
        ],
        detailText: [
            {
                validator: checkdetailText,
                required: false,
                message: "文字介绍不能超过3000个字",
                trigger: "blur",
            },
        ],
        skus: [
            {
                validator: () => { return SkuServices.SkusValid(); },
                required: true,
                message: "请填写正确的商品定价",
                trigger: "blur",
            },
        ],
        specs: [
            {
                validator: () => { return SpecService.SpecsValid(); },
                required: true,
                message: "请填写正确的商品规格",
                trigger: "blur",
            },
        ],
        templateId: [
            { required: true, message: "下单时用户提交信息必须填写", trigger: "blur" },
        ],
        productAfterSalesLabelDTO: [
            {
                validator: () => {
                    return afterSaleSettingRef.value.afterSaleValidate();
                },
                required: true,
                message: "请补充完整售后配置",
                trigger: "blur",
            },
        ]
    });
    eventBus.on(onEditCateParamsLoaded, (newData) => {
        const newVal = newData.data;
        if (!newVal) {
            return;
        }
        for (const key in rules) {
            if (key.indexOf('cateParams') >= 0) {
                delete rules[key];
            }
        }
        for (let i = 0; i < newVal.length; i++) {
            rules["cateParams" + i] = [
                {
                    validator: () => {
                        if (!newVal[i].content) {
                            return true;
                        }
                        if (newVal[i].type == CateParamesType.Text) {
                            const reg = new RegExp(newVal[i].pattern || '');
                            return reg.test(newVal[i].content || '');
                        }
                        if (newVal[i].type == CateParamesType.Amount) {
                            const curValue = parseFloat(newVal[i].content || '');
                            if (newVal[i].minValue === undefined || !newVal[i].maxValue === undefined) {
                                return false;
                            }
                            if (curValue < newVal[i].minValue || curValue > newVal[i].maxValue) {
                                return false;
                            }
                            return true;
                        }
                        if (newVal[i].type == CateParamesType.Time) {
                            return true;
                        }
                        return false;
                    },
                    message: "请输入正确的" + newVal[i].name,
                    trigger: "blur",
                    required: false,
                }
            ];
        }
    });
    return {
        rules,
    };
}
