import { QueryTags } from "@/api/GoodsApi";
import { reactive } from "vue";
export default class TagsService {
    static async GetAllTags() {
        if (this.AllTags.length > 0) {
            return this.AllTags;
        }
        const all = await QueryTags({ pageNum: 1, pageSize: 1000 });
        const list = all.resultData.list;
        list.forEach((item) => {
            this.AllTags.push({ value: item.name, id: item.id });
        });
        return this.AllTags;
    }
    static async GetTagsByText(text) {
        const allTags = await this.GetAllTags();
        const filterTags = allTags.filter(item => item.value ? item.value.indexOf(text) >= 0 : false);
        return filterTags;
    }
}
TagsService.AllTags = reactive(new Array());
