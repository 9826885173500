import { PriceStatus } from "@/common/enum";
import SkuServices from "@/services/Skus/SkuServices";
import { defineComponent, reactive } from "vue";
export default defineComponent({
    setup() {
        const skus = SkuServices.GetSkus();
        const handleInputChanged = () => {
            console;
        };
        const settleStatus = SkuServices.settleStatus;
        const selfSaleStatus = SkuServices.selfSaleStatus;
        const rules = reactive({
            originalPrice: [
                {
                    validator: () => {
                        return skus[0].originalPrice > 0;
                    },
                    required: true,
                    message: "市场价最低不能低于0.01元",
                    trigger: "blur",
                },
            ],
            stock: [
                {
                    validator: () => {
                        return skus[0].stock > 0;
                    },
                    required: false,
                    message: "商品库存不符合规范",
                    trigger: "blur",
                },
            ],
            price: [
                {
                    validator: () => {
                        return skus[0].price > 0;
                    },
                    required: true,
                    message: "商品销售价格不符合规范",
                    trigger: "blur",
                },
            ],
            unPrice: [
                {
                    require: false,
                },
            ],
            selfSalePrice: [
                {
                    validator: () => {
                        return skus[0].selfSalePrice > 0;
                    },
                    required: true,
                    message: "自营销售价格不符合规范",
                    trigger: "blur",
                },
            ],
            unSelfSalePrice: [
                {
                    require: false,
                },
            ],
        });
        const errors = SkuServices.errors;
        const onChange = () => {
            SkuServices.SkusValid();
        };
        const getInputFocus = (event) => {
            if (event.currentTarget.value == '0') {
                event.currentTarget.select();
            }
        };
        return {
            PriceStatus,
            settleStatus,
            selfSaleStatus,
            skus,
            errors,
            handleInputChanged,
            onChange,
            getInputFocus
        };
    },
});
