import SpecService from "@/services/Specs/SpecServices";
import { ElMessage } from "element-plus";
import { defineComponent, reactive } from "vue";
import { SpecContentItem } from "@/services/Specs/SpecModel";
import Upload from "@/components/upload/GoUpload.vue";
import { UploadUrl } from "@/api/GoodsApi";
import { GetImageSize } from "@/common/utils";
export default defineComponent({
    emits: ["onSpecChanged", "removeSpec"],
    components: {
        Upload,
    },
    props: {
        spec: {
            type: Object,
            required: true,
        },
        specIndex: {
            type: Number,
            required: true,
        },
    },
    setup(props, { emit }) {
        const item = reactive(props.spec);
        const onImageChange = (val) => {
            if (!val) {
                for (let i = 0; i < item.contentList.length; i++) {
                    item.contentList[i].pics = "";
                    SpecService.UpdatePics(props.specIndex, i, "");
                }
            }
        };
        const delSpecContent = (index) => {
            item.contentList.splice(index, 1);
            emit("onSpecChanged", item);
        };
        const addSpec = () => {
            if (item.contentList.length >= 40) {
                ElMessage.warning("规格内容不能超过40个");
                return;
            }
            const newItem = new SpecContentItem();
            newItem.content = "";
            newItem.pics = "";
            item.contentList.push(newItem);
            // SpecService.AddSpecContent(props.specIndex, newItem);
        };
        const onContentChanged = (index) => {
            emit("onSpecChanged", item);
        };
        const onSpecNameChanged = () => {
            emit("onSpecChanged", item);
        };
        const removeSpec = () => {
            emit("removeSpec");
        };
        const beforeUpload = (val) => {
            return new Promise((reslove, reject) => {
                GetImageSize(val).then((res) => {
                    if (res.width != res.height) {
                        ElMessage.error("图片上传失败，图片尺寸比例应为1:1");
                        reject();
                    }
                    reslove(res);
                });
            });
        };
        const addSpecImgSuccess = (index, val) => {
            item.contentList[index].pics = val.resultData;
            SpecService.UpdatePics(props.specIndex, index, val.resultData);
        };
        const delSpecContentImg = (item, index) => {
            item.contentList[index].pics = "";
            SpecService.UpdatePics(props.specIndex, index, "");
        };
        const errors = SpecService.errors;
        const isError = (row, key) => {
            for (let i = 0; i < errors.length; i++) {
                if (errors[i].id == row.id && errors[i].key == key) {
                    return true;
                }
            }
            return false;
        };
        return {
            item,
            UploadUrl,
            onImageChange,
            delSpecContent,
            addSpec,
            onContentChanged,
            onSpecNameChanged,
            removeSpec,
            beforeUpload,
            addSpecImgSuccess,
            delSpecContentImg,
            errors,
            isError,
        };
    },
});
