import { SpecItem } from "@/services/Specs/SpecModel";
import eventBus from '@/utils/eventBus';
import SpecService from "@/services/Specs/SpecServices";
import { SpecContentItem } from "@/services/Specs/SpecModel";
import SkuServices from "@/services/Skus/SkuServices";
import { specChanged } from "@/utils/eventBusConst";
export default function specsFunc() {
    const onSpecChanged = (newItem, index) => {
        SpecService.Replace(index, newItem);
        if (!SpecService.SpecCheck()) {
            return;
        }
        eventBus.emit(specChanged);
    };
    const addSpec = () => {
        const specs = SpecService.GetSpecs();
        if (!SpecService.SpecCheck()) {
            return;
        }
        const newSpec = new SpecItem();
        newSpec.name = "";
        newSpec.type = specs.length + 1;
        newSpec.contentList = new Array();
        const newContent = new SpecContentItem();
        newSpec.contentList.push(newContent);
        SpecService.AddSpecs(newSpec);
        eventBus.emit(specChanged);
    };
    const delSpec = (index) => {
        SpecService.RemoveSpecs(index);
        if (SpecService.GetSpecs().length == 0) {
            SkuServices.ClearSkus();
        }
        eventBus.emit(specChanged);
    };
    return {
        addSpec,
        delSpec,
        onSpecChanged
    };
}
